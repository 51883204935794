<template>
  <div class="app" id="app" :class="{ 'auth-view': isAuth, 'no-min-width': $route.name == 'watch' }">
    <icons-sprite />
    <transition name="fade" mode="out-in">
      <switching-teams v-if="switchingTeam && switchTeam" :team="switchTeam" />
    </transition>
    <template v-if="!(switchingTeam && switchTeam)">
      <badge-sprite />
      <language-icons-sprite />
      <temporary-mobile v-if="false" />
      <trial-top-bar v-if="$route.name !== 'onboarding' && !isAuth && !!trial" />
      <global-sidenav v-if="!isAuth && $store.state.hasLoadedFirstRoute"
        :has-unread-notifications="hasUnreadNotifications" :notifications="notifications"
        :loading-notifications="loadingNotifications" />
      <wisdom-settings-slide-in />
      <import-dialog />
      <main class="main" :class="{ 'with-topbar': $route.name !== 'onboarding' && !isAuth && !!trial }">
        <auth-layout v-if="isAuth" :completed="authCompleted">
          <transition name="only-down" :duration="200" mode="out-in">
            <router-view @completed="authCompleted = true"></router-view>
          </transition>
        </auth-layout>
        <template v-else>
          <transition name="fade" :duration="200" mode="out-in">
            <router-view></router-view>
          </transition>
        </template>
        <upload-list v-if="!isAuth && $store.state.addMedia.uploading" :list="$store.state.addMedia.files"
          :abort="() => $store.dispatch('addMedia/abort')" />
      </main>
      <transcript-editor />
      <trim-editor />
      <!--    <feedback-button />-->
      <achievements-slide-in />
      <widget-editor />
      <wisdom-editor />
      <manage-payment :url="paymentUrl" />
      <sitemap-selector />
      <apps-sidepanel />
      <spotlight-search />
      <person-dialog v-if="!!user" />
      <template v-if="showOverlay">
        <div class="modal show"></div>
        <div class="modal-backdrop"></div>
      </template>
    </template>
  </div>
</template>
<script>
import File from '@app2/models/File';
import Folder from '@app2/models/Folder';
import Widget from '@app2/models/Widget';
import eventBus from '@app2/core/eventBus';
import Echo from '@app2/api/Echo';
import getAllModels from '@app2/utils/allModels';
import { createNamespacedHelpers } from 'vuex';
const { mapState: mapSearchState } = createNamespacedHelpers('search');
import rootVue from "@app2/app"
import exportRouter from '@app2/modules/Audience/Export/export.router'
import importRouter from '@app2/modules/Audience/Import/import.router'
import playlistRouter from '@app2/modules/Library/Builder/playlist.router'
import playlistApp from '@app2/modules/Library/Builder/playlist.app'
import { debounce } from 'lodash'
import hubShortcutMixin from "@app2/utils/hub-shortcut-mixin.js"
export default {
  name: 'app',
  metaInfo() {
    return {
      title: 'Membership.io',
      titleTemplate: `Membership.io | %s`
    }
  },
  watch: {
    '$route.fullPath'() {
      eventBus.$emit('routeUpdated')
    },
    $route(to, from) {
      this.updateMetaTag();
    },
  },
  components: {
    AchievementsSlideIn: () => import("@app2/modules/Achievements/SlideIn/App.vue"),
    FeedbackButton: () => import("@app2/core/Components/Feedback"),
    IconsSprite: () => import('@app2/core/Icons'),
    BadgeSprite: () => import('@utils/Badge/Sprite'),
    LanguageIconsSprite: () => import('@app2/core/Languages'),
    TrialTopBar: () => import('@app2/core/Components/Banner/TrialTopBar'),
    UploadList: () => import('@app2/core/Components/UploadList/Index.vue'),
    GlobalSidenav: () => import('@app2/core/Components/Sidenav/Container.vue'),
    TranscriptEditor: () => import('@app2/modules/Library/Media/TranscriptEditor/Pages/Index'),
    TrimEditor: () => import('@app2/modules/Library/Media/TrimEditor/Pages/Index'),
    TemporaryMobile: () => import('@app2/core/TemporaryMobile'),
    ImportDialog: () => import('@app2/modules/Audience/Import/App'),
    WidgetEditor: () => import('@app2/modules/Widgets/Pages/Index'),
    WisdomEditor: () => import('@app2/modules/Wisdom/Pages/Index'),
    SitemapSelector: () => import('@app2/modules/Sitemaps/Index.vue'),
    ManagePayment: () => import('@app2/modules/AccountSettings/Components/Modals/ManagePayment.vue'),
    AuthLayout: () => import('@app2/modules/Auth/Components/AuthLayout'),
    WisdomSettingsSlideIn: () => import('@app2/modules/Apps/Pages/Sidepanel/Wisdom/Builder/App.vue'),
    AppsSidepanel: () => import('@app2/modules/Apps/Pages/Sidepanel/Index'),
    SpotlightSearch: () => import('@app2/modules/Spotlight/Index.vue'),
    PersonDialog: () => import('@app2/modules/Audience/Person/App'),
    SwitchingTeams: () => import('@app2/core/Components/SwitchingTeams'),
  },
  mixins: [require('@app-spark/spark'), hubShortcutMixin],
  computed: {
    isAuth() {
      return (this.$route.meta && this.$route.meta.auth) || this.$route.name === 'onboarding';
    },
    widgets() {
      return Widget.all();
    },
    ...mapSearchState(['filters']),
    trial() {
      return this.$store.getters['auth/GET_TRIAL'];
    },
  },
  data() {
    return {
      achievementApp: null,
      paymentUrl: null,
      authCompleted: false,
      showOverlay: false,
      switchingTeam: false,
      switchTeam: null,
    }
  },
  async mounted() {
    if (this.$store.getters['auth/GET_PLAN_ID']?.includes('trial') && this.$store.getters['auth/GET_USER']?.meta?.trial_hash) {
      this.$store.dispatch('auth/fetchPlan', this.$store.getters['auth/GET_USER'].meta.trial_hash);
    }
    await this.$store.dispatch('auth/fetchUser');
    this.updateMetaTag();
    if (this.$store.state.auth.user?.id) this.listenPusher();
    eventBus.$on('loggedIn', () => {
      this.listenPusher();
      this.loadDataForAuthenticatedUser();
    });
    eventBus.$on('toggleOverlay', (val) => {
      this.showOverlay = val;
    });
    eventBus.$on('switchTeam', (team) => {
      this.switchTeam = team;
      this.switchingTeam = true;
    });
    eventBus.$on('loggedOut', () => {
      getAllModels().forEach((model) => {
        if (!model || !model.deleteAll) return;
        try {
          model.deleteAll();
        } catch (e) { }
      });
    });
    eventBus.$on('auth/unauthenticated', () => {
      this.$store.dispatch('auth/unauthenticated');
    });
    eventBus.$on('manage-payment-url', (url) => {
      this.paymentUrl = url;
    });
    eventBus.$on('open-in-library', folder => {
      this.$bvModal.hide('apps-dialog');
    });
    window.addEventListener('message', async (event) => {
      if (event.origin.includes('chargebee.com') && (event.data?.key === "cb.payment_source.add" && event.data?.status === "success") || event.data?.key === "cb.success") {
        this.$store.commit('auth/SET_HAS_PAYMENT_METHOD', true);
        this.$bvModal.hide('manage-payments');
        this.$bvModal.hide('checkout-modal');
        eventBus.$emit('paymentMethodAdded');
        if (this.$route.name !== 'OnboardingDetails') await this.$store.dispatch('auth/fetchUser', true);
      }
    });
    if (localStorage?.redirect_hub && this.$route.name !== 'login') {
      this.redirectHubRequest();
    }
  },
  methods: {
    debouncer: debounce((cb, args) => cb(args), 500),
    toggleMediaModal() {
      if (this.$can('hours')) {
        this.$root.$emit('bv::toggle::modal', 'add-media', '#media-toggle');
      } else {
        if (!!this.trial) {
          this.$bvModal.show('trial-limit-modal');
        } else {
          this.$bvModal.show('quota-limit-modal');
        }
      }
      // this.$toasted.show('Cannot add anymore files, quota limit reached!', { type: 'error' });
    },
    updateMetaTag() {
      // Logic for updating meta tag (showing scaled desktop for mobile users)
      if (!this.$store.getters['isTablet']) return;
      const viewportEl = document.querySelector('head meta[name="viewport"]');
      if (!this.isAuth && this.$store.getters['isTablet'] && this.$route.name !== 'watch') {
        viewportEl.setAttribute('content', 'width=1200');
      } else {
        viewportEl.setAttribute('content', 'width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0');
      }
    },
    async fileSuggestionToast(event) {
      await File.api().get(event.file.hash, { params: { include: ['folder', 'tags'] } })
      this.debouncer(() => {
        this.$toasted.show(`<p class="txt-body mb-1">Searchie AI suggestions for <b>${event.file.title}</b> are ready to view.</p>`, {
          duration: 120000,
          type: 'success',
          position: 'bottom-right',
          className: 'searchie-toast searchie-toast-small',
          action: [
            {
              text: 'View suggestions',
              onClick: (e, toastObject) => {
                if (this.$route.path.includes('/library')) {
                  playlistRouter.replace({ name: 'MediaPage', params: { hash: event.file.hash, noBackRoute: true } }).then(() => {
                  })
                    .catch(() => { })
                    .finally(() => {
                      this.$nextTick(() => {
                        playlistApp.$bvModal.show('playlist-form-dialog');
                        setTimeout(() => {
                          this.$bvModal.show('ai-suggestions-modal');
                          eventBus.$emit('open-searchieai-modal')
                        }, 300)
                      });
                    });
                  toastObject.goAway(0)
                }
                if (!this.$route.path.includes('/library')) {
                  setTimeout(() => {
                    const route = this.$router.resolve({ name: 'library', query: { file: event.file.hash, showAi: true } });
                    window.open(route.href, '_blank')
                  }, 300)
                  toastObject.goAway(0)
                }
              }
            },
            {
              text: 'Dismiss',
              onClick: (e, toastObject) => {
                toastObject.goAway(0)
              },
            },
            {
              text: 'X',
              class: 'close',
              onClick(e, toastObject) {
                toastObject.goAway(0);
              }
            }
          ]
        });
      }, 25000)
    },
    listenPusher() {
      Echo.private('user.' + Spark.userId)
        .listen('TranscriptionVersionUpdated', (event) => {
          if (event.fileId && event.result) {
            eventBus.$emit('TranscriptionVersionUpdated', event)
          }
        })
        .listen('FileSuggestionCreated', (event) => {
          if (this.$cant('searchie-ai')) return;
          localStorage.setItem('newSuggestionKey', event.type)
          eventBus.$emit('file-suggestion-created', event.type)
          this.fileSuggestionToast(event)
        })
        .listen('FileIsTooBig', (event) => {
          console.log('FileIsTooBig', event)
          this.$toasted.show(`Warning: The "${event.file.title}" file size exceeds 30GB`, { type: 'info' });
        })
        .listen('FileCopilotProcessed', async (event) => {
          console.log('FileCopilotProcessed', event)
          eventBus.$emit('FileCopilotProcessed', event.file)
          File.insertOrUpdate({ data: event.file });
          // await File.api().get(event.file_hash, { params: {include: ['folder', 'tags']} })
        })
        .listen('FileCopilotFailed', async (event) => {
          console.log('FileCopilotFailed', event)
        })
        .listen('ReplyCommentCreated', (event) => {
          console.log("ReplyCommentCreated", event)
        })
        .listen('FolderUpdated', (event) => {
          console.log("FolderUpdated", event)
          Folder.insertOrUpdate({ data: event.folder });
        })
        .listen('MediaTrimProcessing', (event) => {
          console.log("MediaTrimProcessing", event)
          eventBus.$emit('MediaTrimProcessing', event.file)
        })
        .listen('FileStatusUpdated', (event) => {
          console.log("FileStatusUpdated", event)
          File.insertOrUpdate({ data: event.file });
          eventBus.$emit('FileStatusUpdated', event.file)
          if (event.file.status_transcription === 'transcription_processed') {
            this.$store.commit('v2/library/SET_IMPORT_ALL_FILES', event.file.hash);
          }
          this.getNotifications();
        })
        .listen('AudienceImportSuccess', (event) => {
          console.log("AudienceImportSuccess", event)
          this.$toasted.show(`<p class="txt-body mb-1">Import <b>${event.import.title}</b> is complete and ready to view!</p>`, {
            duration: 7000,
            type: 'success',
            action: {
              text: 'View imports',
              onClick: (e, toastObject) => {
                importRouter.push({ name: 'RecentImports' }).catch(() => { })
                rootVue.$bvModal.show('import-dialog');
              },
            },
          });
        })
        .listen('AudienceExportGenerated', (event) => {
          console.log("AudienceExportGenerated", event)
          eventBus.$emit('audienceExportGenerated', event.exportData)
          if (event.exportData.status === 'done') {
            this.$toasted.show(`<p class="txt-body mb-1">Export <b>${event.exportData.title}</b> is ready for download!</p>`, {
              duration: 7000,
              type: 'success',
              action: {
                text: 'View exports',
                onClick: (e, toastObject) => {
                  exportRouter.push({ name: 'RecentExports' }).catch(() => { })
                  rootVue.$bvModal.show('export-dialog');
                },
              },
            });
          }
          if (event.exportData.status === 'failed') {
            this.$toasted.show(`<p class="txt-body mb-1">Export <b>${event.exportData.title}</b> could not be generated.</p>`, {
              duration: 7000,
              type: 'error',
              action: {
                text: 'View exports',
                onClick: (e, toastObject) => {
                  exportRouter.push({ name: 'RecentExports' }).catch(() => { })
                  rootVue.$bvModal.show('export-dialog');
                },
              },
            });
          }
        })
        .listen('AudienceImportSuccess', (event) => {
          console.log("AudienceImportSuccess 2", event)
          this.getNotifications();
        })
        .listen('AudienceImportFailed', (event) => {
          console.log("AudienceImportFailed", event)
          this.getNotifications();
        })
        .listen('FileTagAttached', (event) => {
          console.log("FileTagAttached", event)
          eventBus.$emit('refresh-playlists')
        })
        .listen('FileTagDetached', (event) => {
          console.log("FileTagDetached", event)
          eventBus.$emit('refresh-playlists')
        })
        .listen('UserSessionUpdated', (event) => {
          this.$store.dispatch('auth/fetchUser'); //Check if user is still logged in
        })
        .notification((notification) => {
          this.getNotifications();
          this.$toasted.show(`<p class="txt-body mb-1">${notification.body}</p>`, {
            duration: 7000,
            action: {
              text: notification.action_text,
              onClick: (e, toastObject) => {
                if (notification.action_url) this.$router.push(notification.action_url);
              },
            },
          });
        });
    },
  },
};
</script>
